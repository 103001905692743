import axios, { AxiosResponse } from 'axios';
import { ManagerDashboardModel } from '../Model/ManagerDashboard';

const API_URL = process.env.VUE_APP_API_URL;

export class ManagerDashboardController extends ManagerDashboardModel {
  gasStationId = 0;

  constructor(managerId, startDate, endDate, gasStationId) {
    super(managerId, startDate, endDate, gasStationId);

    this.gasStationId = gasStationId;
  }
  public async targetsData(){
    const data = await this.getTargets.then((item) => {
      return item.data;
    });

    return data;
  }

  public async cardsData() {
    const data = await this.getCards.then((item) => {
    
      return item.data;
    });

    return data;
  }

  public async fuelLitersData(): Promise<AxiosResponse> {
    const data = await this.getFuelLiters.then((item) => {
      return item.data;
    });

    return data;
  }

  public async revenueData(): Promise<AxiosResponse> {
    const data = await this.getRevenue.then((item) => {
      return item.data;
    });

    return data;
  }

  public async costsData(): Promise<AxiosResponse> {
    const data = await this.getCosts.then((item) => {
      return item.data;
    });

    return data;
  }

  public async financialAnalysisData(): Promise<AxiosResponse> {
    const data = await this.getFinancialAnalysis.then((item) => {
      return item.data;
    });

    return data;
  }

  public async evolutionData(): Promise<AxiosResponse> {
   
    const data = await this.getEvolution.then((item) => {
   
      return item.data;
    });

    return data;

  }

  public async getClientEvolutionChartData() {
    const data = await this.getClientEvolutionChart().then((item) => {
      return item.data;
    });

    return data;
  }

  public async getClientRegistrationsDataByMonth() {
    const data = await this.getClientRegistrationsByMonth().then((item) => {
      return item.data;
    });

    return data;
  }

  public async getFuelTransactionEvolutionChartData() {
    const data = await this.getFuelTransactionEvolutionChart().then((item) => {
      return item.data;
    });

    return data;
  }

  public async getFuelTransactionDataByMonth() {
    const data = await this.getFuelTransactiontByMonth().then((item) => {
      return item.data;
    });

    return data;
  }

  public async paymenthMethodsData(): Promise<AxiosResponse> {
    const data = await this.getPaymentMethods(this.gasStationId).then(
      (item) => {
        return item.data;
      }
    );

    return data;
  }

  public async cardDetailsGalonage() {
    return await this.getCardDetailsGalonage();
  }

  public async cardDetailsRegistration() {
    return await this.getCardDetailsRegistration();
  }

  public async cardDetailsFidelization() {
    return await this.getCardDetailsFidelization();
  }

  public async getFidelizationDataCard(): Promise<AxiosResponse<any>> {
    return await this.getFidelizationData();
  }
}

export class ClientDashboardController {
  private _getUserGraphic(userId: number) {
    return axios.get(`${API_URL}/graphic/${userId}`);
  }

  private _getAmountByMonth(clientId: number, year: number) {
    return axios.get(
      `${API_URL}/user/client/graphic/emitted-and-offset-by-month?client_id=${clientId}&year=${year}`
    );
  }

  private _getFuelTransactionDetailed(clientId: number, year: number) {
    return axios.get(
      `${API_URL}/user/client/table/fuel-transaction-detailed?client_id=${clientId}&year=${year}`
    );
  }

  private _getExchangeCopointsByClient(clientId: number) {
    return axios.get(`${API_URL}/exchangeEcopoint/findByClientId/${clientId}`);
  }

  public getFuelTransactionByClient(clientId: number) {
    return axios.get(`${API_URL}/fuelTransaction/findByClientId/${clientId}`);
  }
  public getFuelTransactionTotalChartData(clientId: number){
    return axios.get(`${API_URL}/fuelTransaction/count-transactions-client?client_id=${clientId}`);
  }

  private _getFuelTransactionByMonth(clientId: number, year: number) {
    return axios.get(
      `${API_URL}/user/client/graphic/fuel-transaction-by-month?client_id=${clientId}&year=${year}`
    );
  }

  public getCashbackInAndOut(clientId: number, gasStationId: number) {
    return axios.get(
      `${API_URL}/exchangeEcopoint/cashback?client_id=${clientId}&gas_station_id=${gasStationId}`
    );
  }

  public getCashbackByClientAndGasStation(
    clientId: number,
    gasStationId: number
  ) {
    return axios.get(
      `${API_URL}/user/client/cashback-by-gas-station?client_id=${clientId}&gas_station_id=${gasStationId}`
    );
  }

  public getTransactionsByNetwork(clientId: number, networkId: number){
    return axios.get(`${API_URL}/exchangeEcopoint/cashback?client_id=${clientId}&network_id=${networkId}`)
  }

  public getNetworksByTransactionsMade(clientId: number){
    return axios.get(`${API_URL}/network/get-by-client/${clientId}`)
  }
}
