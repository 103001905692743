
import loading from "vue3-loading-overlay";
import { Vue, Options } from "vue-class-component";
import UserController from "@/AppClubCarby/Controller/UserController";
import GasStationController from "@/AppClubCarby/Controller/GasStationController";
import Swal from "sweetalert2";
import NameInputComponent from "@/AppClubCarby/Components/NameInputComponent/NameInputComponent.vue";
import { phoneRemoveMask } from "@/mixins";
import useVuelidate from "@vuelidate/core";
import { required, minLength, email } from "@vuelidate/validators";
import { createLogger } from "vuex";

@Options({
  created() {
    this.isLoading = true;
    if (this.$route.params) {
      this.user.profile = this.$route.params.profile;
      this.userController
        .getOneUserById(this.$route.params.profile, this.$route.params.id)
        .then((item) => {
          this.userEdit.user.name = item.user.name;
          this.userEdit.user.address.cep = item.user.address.cep;
          this.userEdit.user.address.city = item.user.address.city;
          this.userEdit.user.address.country = item.user.address.country;
          this.userEdit.user.address.complement = item.user.address.complement;
          this.userEdit.user.address.street = item.user.address.street;
          this.userEdit.user.address.surname = item.user.address.surname;
          this.userEdit.user.address.district = item.user.address.district;
          this.userEdit.user.address.id = item.user.address.id;
          this.userEdit.user.address.uf = item.user.address.uf;
          this.userEdit.user.address.number = item.user.address.number;
          this.userEdit.user.birthDate = item.user.birthDate;
          this.userEdit.user.cpf = item.user.cpf;
          this.userEdit.user.email = item.user.email;
          this.userEdit.user.gender = item.user.gender;
          this.userEdit.user.id = item.user.id;
          this.userEdit.user.profile = item.user.profile;

          if (this.user.profile === "CLIENT") {
            this.userEdit.favoriteGasStations = item.favoriteGasStations.map(
              (elem) => ({ id: elem.id })
            );
          }
          this.userEdit.user.isCompleted = item.user.isCompleted;

          this.userEdit.user.nationality = item.user.nationality;
          this.userEdit.user.phone = item.user.phone;

          if (item.favoriteGasStations) {
            this.userEdit.favoriteGasStations = item.favoriteGasStations.map(
              (elem) => ({ id: elem.id })
            );
          }

          this.userEdit.user.isActive =
            item.user.isActive === true ? "active" : "inactive";

          if (item.id) {
            this.userEdit.id = item.id;
          } else if (item.userId) {
            this.userEdit.id = item.userId;
          }

          if (this.userEdit.user.profile === "ATTENDANT") {
            this.userEdit.attendantCode = item.attendantCode;
            this.gasStationId.gasStations = item.gasStation.id;
            this.gasStationName = item.gasStation.fantasyName;
            this.gasStation.gasStation.id = item.gasStation.id;
          } else if (this.user.profile === "MANAGER") {
            this.gasStations = item.gasStations;
            item.gasStations.forEach((el) => {
              this.gasStationId.gasStations.push(el);
            });
            this.gasStationName = item.gasStations[0].fantasyName;
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
      this.checkProfile(this.user.profile);
    }
  },
  data() {
    return {
      v$: useVuelidate(),
      isLoading: false,
      user: {
        profile: "",
      },
      userController: new UserController(),
      gasStationController: new GasStationController(),
      userEdit: {
        attendantCode: null,
        id: null,
        user: {
          address: {
            cep: null,
            city: null,
            country: null,
            complement: null,
            district: null,
            id: null,
            number: null,
            street: null,
            surname: null,
            isActive: null,
            uf: null,
          },
          birthDate: null,
          cpf: null,
          email: null,
          phone: null,
          gender: null,
          id: null,
          isCompleted: null,
          nationality: null,
          profile: null,
        },
      },
      favoriteGasStations: [],
      gasStationName: null,
      gasStationId: {
        gasStations: [],
      },
      gasStation: {
        gasStation: {
          id: null,
        },
      },
      gasStations: {},
    };
  },
  validations() {
    return {
      userEdit: {
        user: {
          name: { required },
          email: { email },
          cpf: { required, minLength: minLength(14) },
        },
      },
    };
  },
  methods: {
    updateUser() {
      this.userEdit.user.isActive =
        this.userEdit.user.isActive == "active" ? true : false;
      if (this.userEdit.profile === "MANAGER") {
        this.userEdit = { ...this.userEdit, ...this.gasStationId };
      } else if (this.userEdit.user.profile === "ATTENDANT") {
        this.userEdit = { ...this.userEdit, ...this.gasStation };
      }
      this.v$.$validate();

      if (!this.v$.$error) {
        if (
          this.userEdit.user.phone &&
          this.userEdit.user.phone.length === 18
        ) {
          this.userEdit.user.phone = phoneRemoveMask(this.userEdit.user.phone);
        } else if (this.userEdit.user.phone === "") {
          this.userEdit.user.phone = null;
        }

        if (this.userEdit.user.email === "") {
          this.userEdit.user.email = null;
        }

        this.userController
          .editUser(this.$route.params.profile, this.userEdit)
          .then(() => {
            Swal.fire({
              customClass: {
                actions: "modal-register-actions",
                confirmButton: "modal-btn modal-btn-confirm",
                icon: "modal-register-icon",
                title: "modal-register-title",
                container: "modal-register success",
                closeButton: "modal-register-close",
              },
              title: "Yay!",
              text: "Usuário editado com sucesso!",
              icon: "success",
              confirmButtonText: "Ok",
            });
          })
          .catch((error: any) => {
            this.isLoading = false;
            Swal.fire({
              customClass: {
                actions: "modal-register-actions",
                confirmButton: "modal-btn modal-btn-confirm",
                icon: "modal-register-icon",
                title: "modal-register-title",
                container: "modal-register error",
                closeButton: "modal-register-close",
              },
              title: "Oops!",
              text: "Ocorreu algum erro! Tente novamente mais tarde",
              icon: "error",
              confirmButtonText: "Ok",
            });
          });
      } else {
        this.isLoading = false;
        Swal.fire({
          customClass: {
            actions: "modal-register-actions",
            confirmButton: "modal-btn modal-btn-confirm",
            icon: "modal-register-icon",
            title: "modal-register-title",
            container: "modal-register error",
            closeButton: "modal-register-close",
          },
          title: "Oops!",
          text: "Algum erro inesperado aconteceu!",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    today() {
      var date = new Date();

      return date;
    },
    checkProfile(profile) {
      if (profile === "ATTENDANT" || profile === "MANAGER") {
        this.userEdit = Object.assign(this.userEdit, this.gasStation);
      }
    },
  },
  components: {
    loading,
    NameInputComponent,
  },
})
export default class EditRegisterData extends Vue {}
